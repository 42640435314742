import "./styles.css";
import { memo } from "react";
import { CSVData } from "../../types/index";
import infoTexts from "./RewardInfoTexts.json";
import { Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { convertStakedDays } from "./convertStakedDays";
type RewardProps = {
  data: CSVData[] | undefined;
};
function handleNumberDisplay(data: number) {
  if (data % 1 === 0) return String(data).slice(0, -2);
  else return String(data);
}
const RewardSection = (props: RewardProps) => {
  const { data } = props;

  return (
    <div id="reward_data_section">
      <div className="data_card">
        <div>
          <p style={{ margin: "5px" }}>Reward Statistics</p>
        </div>
        <div id="reward_data_container">
          {data && data[0].total_to_pay && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                Total Payout
                <Tooltip title={infoTexts.total_to_pay} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {handleNumberDisplay(data[0].total_to_pay)} CWEB
              </p>
            </div>
          )}

          {data && data[0].total_reward && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                Total Reward
                <Tooltip title={infoTexts.total_reward} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {handleNumberDisplay(data[0].total_reward)} CWEB
              </p>
            </div>
          )}
          {data && data[0].total_collateral && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                Total Collateral
                <Tooltip title={infoTexts.total_collateral} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {handleNumberDisplay(data[0].total_collateral)} CWEB
              </p>
            </div>
          )}

          {data && data[0].staked && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                Staked
                <Tooltip title={infoTexts.staked} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {handleNumberDisplay(data[0].staked)} CWEB
              </p>
            </div>
          )}
          {data && data[0].reward && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                Reward
                <Tooltip title={infoTexts.reward} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {handleNumberDisplay(data[0].reward)} CWEB
              </p>
            </div>
          )}
          {data && data[0].staked_days && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                Staked Days
                <Tooltip title={infoTexts.staked_days} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {convertStakedDays(
                  data[0].staked_days,
                  data[0].staked
                ).toLocaleString()}
              </p>
            </div>
          )}

          {data && data[0].ost_cweb_amount && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                OST CWEB Amount
                <Tooltip title={infoTexts.ost_cweb_amount} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {handleNumberDisplay(data[0].ost_cweb_amount)} CWEB
              </p>
            </div>
          )}

          {data && data[0].ost_rewards && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                OST Rewards
                <Tooltip title={infoTexts.ost_rewards} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {handleNumberDisplay(data[0].ost_rewards)} CWEB
              </p>
            </div>
          )}
          {data && data[0].ost_staked_days && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                OST Staked Days
                <Tooltip title={infoTexts.ost_staked_days} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">
                {convertStakedDays(
                  data[0].ost_staked_days,
                  data[0].ost_cweb_amount
                ).toLocaleString()}
              </p>
            </div>
          )}
          {data && data[0].unstake_date_s && data[0].unstake_date_s !== "" && (
            <div className="reward_grid_cell">
              <p className="cell_header cell_padding color_bone">
                Date of the Unstake
                <Tooltip title={infoTexts.unstake_date_s} arrow>
                  <InfoIcon
                    style={{
                      fontSize: "18px",
                      marginLeft: "8px",
                      cursor: "pointer",
                      color: "#b0b0b0",
                    }}
                  />
                </Tooltip>
              </p>
              <p className="cell_data color_white">{data[0].unstake_date_s}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(RewardSection);
