import { Contract } from 'web3-eth-contract';

export interface IContract {
  contract: Contract;
  address: string;
}

export enum WalletType {
  MetaMask = 'metamask',
  WalletConnect = 'walletconnect',
}



export interface CSVData {
  address: string;
  total_to_pay: number;
  total_reward: number;
  total_collateral: number;
  collateral: number;
  reward: number;
  staked: number;
  staked_days: number;
  collateral_am: number;
  reward_am: number; 
  staked_am: number; 
  staked_days_am: number; 
  collateral_aa: number;
  reward_aa: number;
  staked_aa: number;
  staked_days_aa: number;
  ost_rewards: number;
  ost_cweb_amount: number;
  ost_staked_days: number;
  unstake_date_s: string;
}