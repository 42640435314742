import "./styles.css";
import { useState, memo, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { determineEarlyStakers } from "./determineEarlyStaker";
import { GlobalStatistics } from "../GlobalStatistics/GlobalStatistics";
import { infoText } from "../../texts";
import { WalletButton, StakeDivider } from "../../CustomizedMui";
import MyStatisticsCard from "../LocalStatistics/MyStatisticsCard";
import StakeSection from "./StakeSection";
import RewardSection from "./RewardSection";
import Statistics from "../../assets/Icons/statistics.svg";
import Boost from "../../assets/Icons/boost.svg";
import "./styles.css";
import { useWallet } from "../../contexts";
import { WalletType } from "../../types";
import {
  REACT_APP_CURRENT_BOOST_OFF,
  REACT_APP_INCREASED_OFF,
  REACT_APP_TOTAL_STAKE_STATIS_OFF,
  REACT_APP_NEXT_BOOSTER_WINDOW,
  REACT_APP_RESTRUCTURE_TEXT,
  REACT_APP_LIVENESS_ENDPOINT,
  REACT_APP_STAKING_PAYOUT,
} from "../../conf";
import Papa from "papaparse";
import {
  useLocalStakingStatisticsForLazyQuery,
  useGetBoosterWindowLazyQuery,
} from "../../generated/graphql";
import { CSVData } from "../../types/index";
import {
  StakingData,
  availableBalanceForStaking,
  BoosterData,
  StakerType,
} from "./model";
import Timer from "./Timer";

type StakingProps = {
  width: number;
  activeMetamask: boolean;
};

const warning_text =
  "I'm having problems reading the data i need, please try again later.";
const warning_text_restructured =
  "Staking page is being restructured and might show some discrepancies while being redeployed.";

const prepareAddress = (address: string | undefined) => {
  if (address && address.length === 42 && address.startsWith("0x")) {
    return address.substring(2);
  } else {
    return undefined;
  }
};

const Staking = (props: StakingProps) => {
  const { activeMetamask, width } = props;
  const { connect, account, tokenBalance } = useWallet();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramAccount = searchParams.get("account");
  const stakingPaid = REACT_APP_STAKING_PAYOUT === "true";
  const [getLocalStatisticsFn, { data: localStatisticsData }] =
    useLocalStakingStatisticsForLazyQuery();

  const [getBoosterWindowFn, { data: boosterData }] =
    useGetBoosterWindowLazyQuery();

  const stakingStatisticsFor = localStatisticsData?.stakingStatisticsFor;
  const boosterStatisticsFor = boosterData?.getBoosterWindow;
  const [amountToStake, setAmountToStake] = useState<number>(0);
  const [beWarning, setBeWarning] = useState<boolean>(false);
  const [activeBooster, setActiveBooster] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [amountToStakeError, setamountToStakeError] = useState<boolean>(false);
  const [stakerType, setStakerType] = useState<StakerType | undefined>(
    undefined
  );
  const [data, setData] = useState<CSVData[]>();

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        const response = await fetch("/output1.csv");
        const csvText = await response.text();

        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result: any) => {
            if (result.data && paramAccount) {
              const filteredData = (result.data as CSVData[]).filter(
                (row) =>
                  row.address &&
                  row.address.toLowerCase() === paramAccount.toLowerCase()
              );

              setData(filteredData);
            }
          },
        });
      } catch (error) {
        console.error("Error loading CSV:", error);
      }
    };
    if (stakingPaid) fetchCSV();
  }, [paramAccount, stakingPaid]);

  const handleAmountToStake = (amount: number) => {
    if (amount <= availableBalanceForStake) {
      setamountToStakeError(false);
      setAmountToStake(amount);
    } else {
      setamountToStakeError(true);
      setAmountToStake(availableBalanceForStake);
    }
  };
  const model: StakingData = {
    forAddr: stakingStatisticsFor ?? {
      stakedCwebDays: 0,
      currentStakedCweb: 0,
      totalForfeitedCwebCollateral: 0,
      totalAccumulatedL2Rewards: 0,
      totalForfeitedL2Rewards: 0,
      totalStakedCwebCollateral: 0,
      currentBoostStakeCweb: 0,
      currentBoostForfeitedL2: 0,
      totalBoostRewards: 0,
      amountOfStakers: 0,
    },
    tokenBalance,
    amountToStake,
  };

  const booster: BoosterData = {
    booster: boosterStatisticsFor ?? {
      endsAt: 0,
      rewardPool: 0,
      startsAt: 0,
    },
  };
  const availableBalanceForStake = availableBalanceForStaking(model);

  useEffect(() => {
    if (REACT_APP_LIVENESS_ENDPOINT)
      axios.get(REACT_APP_LIVENESS_ENDPOINT).then((response) => {
        if (response.status === 200) {
          setIsReady(true);
        } else {
          setIsReady(false);
        }
      });
  }, []);

  const CallGlobalStatistic = useCallback(() => {
    try {
      const queryResult = GlobalStatistics();
      return queryResult;
    } catch (error) {
      console.log(error);
    }
  }, []);
  const globalData = CallGlobalStatistic();

  const handleMetamask = () => {
    connect(WalletType.MetaMask);
  };
  const getLocalStatistics = useCallback(() => {
    if (account && account.length > 0) {
      setSearchParams({ account: account });
      getLocalStatisticsFn({
        variables: {
          address: prepareAddress(account),
        },
      });
    }
    if (paramAccount !== null && paramAccount !== account) {
      getLocalStatisticsFn({
        variables: {
          address: prepareAddress(paramAccount),
        },
      });
    }
  }, [account, getLocalStatisticsFn, setSearchParams, paramAccount]);

  const getBoosterStatistics = useCallback(() => {
    getBoosterWindowFn({
      variables: {},
    });
  }, [getBoosterWindowFn]);

  useEffect(() => {
    if (
      (stakingStatisticsFor?.totalForfeitedCwebCollateral &&
        stakingStatisticsFor?.totalForfeitedCwebCollateral > 0) ||
      (stakingStatisticsFor?.totalForfeitedL2Rewards &&
        stakingStatisticsFor?.totalForfeitedL2Rewards > 0)
    ) {
      setStakerType(StakerType.Unstaker);
    } else {
      if (stakingStatisticsFor?.stakedCwebDays)
        setStakerType(
          determineEarlyStakers(
            stakingStatisticsFor.stakedCwebDays,
            stakingStatisticsFor.currentStakedCweb
          )
            ? StakerType.Earlystaker
            : StakerType.Newstaker
        );
    }
  }, [stakingStatisticsFor]);

  useEffect(() => {
    getBoosterStatistics();
  }, [getBoosterStatistics]);
  useEffect(() => {
    getLocalStatistics();
  }, [account, getLocalStatistics, tokenBalance]);
  // Not sure how long it will take for burn info etc to get noticed
  // We refresh the data, waiting for increasing timespans until we notice a change
  const afterStaking = useCallback(() => {
    const refreshData = async () => {
      const delays = [1, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89];
      for (let i = 0; i < delays.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, delays[i] * 1000));
        await getLocalStatistics();
      }
    };
    refreshData();
  }, [getLocalStatistics]);

  useEffect(() => {
    setBeWarning(globalData === "error");
  }, [globalData]);
  function handleStakingWarningBox() {
    if (stakerType === StakerType.Earlystaker)
      return (
        <>
          <p>
            Get ready to unlock exciting new benefits and rewards! We
            appreciated your loyalty and wanted to give you some extra special
            benefits.
          </p>
          <ul style={{ width: "fit-content", margin: "auto" }}>
            <li className="stake_warning_container_li">
              DOUBLE Staking rewards between the 2nd of April, 2024, and until
              the mainnet release — September 30th, 2024{" "}
            </li>
            <li className="stake_warning_container_li">
              Opportunities to vote on whitelisting of certain projects
            </li>
            <li className="stake_warning_container_li">
              Special airdrop / early-stage allocation privileges
            </li>
            <li className="stake_warning_container_li">Rewards from votings</li>
            <li className="stake_warning_container_li">
              Special collateral arrangements, including for CWEB subsidised
              PACTs
            </li>
            <li className="stake_warning_container_li">
              Unique early access to project information and invite-only
              announcement groups
            </li>
          </ul>
        </>
      );
    if (stakerType === StakerType.Unstaker)
      return (
        <>
          <p>
            We understand that you may have reasons to unstake your tokens.
            However, it's important to note that unstaking removed you from the
            pool of early stakers and you are no longer eligible for the
            associated benefits.
          </p>
          <p>
            We're committed to providing a robust and secure staking experience
            for everyone. To achieve this, we made some key updates to the
            staking system that will be implemented well before the mainnet
            launch:
          </p>
          <ul style={{ width: "fit-content", margin: "auto" }}>
            <li
              className="stake_warning_container_li"
              style={{ fontWeight: "500 !important" }}
            >
              <span style={{ fontWeight: "bold !important" }}>
                Upgrading to WebAssembly Smart Contracts:
              </span>{" "}
              The staking system is migrating to a newer WebAssembly smart
              contract system, enhancing efficiency and security for the staking
              process.
            </li>
            <li className="stake_warning_container_li">
              <span style={{ fontWeight: "900 !important" }}>
                Enhanced Reward and Collateral Delivery:
              </span>{" "}
              We're developing an EVM address compatibility layer. This layer
              ensures seamless transfer of rewards and collateral directly to
              the account associated with the seed phrase used for staking.
            </li>
          </ul>
        </>
      );
  }
  return (
    <div className="staking">
      <div className="header-text">
        {REACT_APP_RESTRUCTURE_TEXT && (
          <div id="important_section" className="be_warning_text">
            <p className="cell_important">{warning_text_restructured}</p>
          </div>
        )}

        <h1>Coinweb staking dashboard</h1>
        <p>{infoText}</p>
        <button
          onClick={() =>
            window.open(
              "https://medium.com/@Coinweb.io/guide-to-staking-cweb-8032b11599b1"
            )
          }
          id="guide_btn"
        >
          Guide to Staking CWEB
        </button>
      </div>

      {activeMetamask ? (
        <div className="wallet_section">
          <WalletButton
            type="button"
            onClick={() => handleMetamask()}
            variant="contained"
            disabled={!!account}
          >
            {account ? "WALLET CONNECTED" : "CONNECT YOUR WALLET"}
          </WalletButton>
        </div>
      ) : null}
      {beWarning || !isReady ? (
        <div id="important_section">
          <p className="cell_important be_warning_text">{warning_text}</p>
        </div>
      ) : null}
      {paramAccount && stakingPaid && <RewardSection data={data}></RewardSection>}

      {account && (
        <>
          <StakeSection
            totalBoostStakedCweb={
              globalData !== "loading" &&
              globalData !== "error" &&
              globalData !== undefined &&
              globalData !== null
                ? globalData.currentBoostStakeCweb
                : 0
            }
            rewardPool={booster.booster.rewardPool}
            activeBooster={activeBooster}
            handleCweb={handleAmountToStake}
            metamaskAccount={account}
            afterStaking={afterStaking}
            model={model}
            availableBalanceForStake={availableBalanceForStake}
            isReady={isReady}
            amountToStakeError={amountToStakeError}
          ></StakeSection>
        </>
      )}

      <StakeDivider></StakeDivider>

      <div id="card_data_section">
        <MyStatisticsCard
          model={localStatisticsData ? model : undefined}
          stakerType={stakerType}
          rewardData={data}
        ></MyStatisticsCard>
        {width > 600 ? (
          <>
            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="available_boost"
                    className="card_icon shadow"
                    src={Boost}
                    width={47}
                  ></img>
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title">Available Boost</p>
              </div>
              <div className="data_card_global_data">
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding  color_bone">
                    Current boost pool
                  </p>
                  <p className="cell_data color_white">
                    {booster.booster.rewardPool !== undefined &&
                    booster.booster.rewardPool
                      ? booster.booster.rewardPool.toLocaleString() + " CWEB"
                      : "- -"}{" "}
                  </p>
                </div>
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Increased last 24 hours
                  </p>
                  <p className="cell_data color_white">
                    {REACT_APP_INCREASED_OFF ? (
                      <p className="cell_data color_white">N/A</p>
                    ) : (
                      <p className="cell_data color_white">
                        {globalData !== undefined &&
                        globalData &&
                        globalData !== "loading" &&
                        globalData !== "error"
                          ? globalData.forfeitedL2Rewards24H.toLocaleString() +
                            " CWEB"
                          : "- -"}
                      </p>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="statistics"
                    className="card_icon shadow"
                    src={Statistics}
                    width={47}
                  ></img>
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title ">Statistics</p>
              </div>
              <div className="data_card_global_data">
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Total staked
                  </p>
                  {REACT_APP_TOTAL_STAKE_STATIS_OFF ? (
                    <p className="cell_data color_white">N/A</p>
                  ) : (
                    <p className="cell_data color_white">
                      {globalData !== undefined &&
                      globalData &&
                      globalData !== "loading" &&
                      globalData !== "error"
                        ? Number(
                            globalData.totalStakedCweb.toFixed(0)
                          ).toLocaleString() + " CWEB"
                        : "- -"}
                    </p>
                  )}
                </div>
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Number of stakers
                  </p>
                  <p className="cell_data color_white">
                    {globalData !== undefined &&
                    globalData &&
                    globalData !== "loading" &&
                    globalData !== "error"
                      ? Number(
                          globalData.amountOfStakers.toFixed(0)
                        ).toLocaleString()
                      : "- -"}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="statistics"
                    className="card_icon shadow"
                    src={Statistics}
                    width={47}
                  ></img>
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title ">Statistics</p>
              </div>
              <div className="data_card_global_data">
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Total staked
                  </p>
                  {REACT_APP_TOTAL_STAKE_STATIS_OFF ? (
                    <p className="cell_data color_white">N/A</p>
                  ) : (
                    <p className="cell_data color_white">
                      {globalData !== undefined &&
                      globalData &&
                      globalData !== "loading" &&
                      globalData !== "error"
                        ? Number(
                            globalData.totalStakedCweb.toFixed(0)
                          ).toLocaleString() + " CWEB"
                        : "- -"}
                    </p>
                  )}
                </div>
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Amount of stakers
                  </p>
                  <p className="cell_data color_white">
                    {globalData !== undefined &&
                    globalData &&
                    globalData !== "loading" &&
                    globalData !== "error"
                      ? Number(
                          globalData.amountOfStakers.toFixed(0)
                        ).toLocaleString()
                      : "- -"}
                  </p>
                </div>
              </div>
            </div>
            <div className="data_card">
              <div className="data_card_header color_white">
                <div className="card_icon_container">
                  <img
                    alt="available_boost"
                    className="card_icon shadow"
                    src={Boost}
                    width={47}
                  ></img>
                  <span className="stripe-right header_stripe"></span>
                </div>
                <p className="card_header_title">Available Boost</p>
              </div>
              <div className="data_card_global_data">
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding  color_bone">
                    Current boost pool
                  </p>
                  {REACT_APP_CURRENT_BOOST_OFF ? (
                    <p className="cell_data color_white">N/A</p>
                  ) : (
                    <p className="cell_data color_white">
                      {booster.booster.rewardPool !== undefined &&
                      booster.booster.rewardPool
                        ? booster.booster.rewardPool.toLocaleString() + " CWEB"
                        : "- -"}{" "}
                    </p>
                  )}
                </div>
                <div className="data_card_grid_cell">
                  <p className="cell_header cell_padding color_bone">
                    Increased last 24 hours
                  </p>
                  <p className="cell_data color_white">
                    {REACT_APP_INCREASED_OFF ? (
                      <p className="cell_data color_white">N/A</p>
                    ) : (
                      <p className="cell_data color_white">
                        {globalData !== undefined &&
                        globalData &&
                        globalData !== "loading" &&
                        globalData !== "error"
                          ? globalData.forfeitedL2Rewards24H.toLocaleString() +
                            " CWEB"
                          : "- -"}
                      </p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {stakerType && (
        <>
          <StakeDivider></StakeDivider>
          <div id="stake_warning_container">
            <p style={{ marginTop: "0px" }}>Dear staker,</p>
            {handleStakingWarningBox()}
          </div>
        </>
      )}

      {REACT_APP_NEXT_BOOSTER_WINDOW === "true" ? (
        <Timer booster={booster} setActive={setActiveBooster}></Timer>
      ) : null}
    </div>
  );
};
export default memo(Staking);
